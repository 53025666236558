@use "src/css/var/var.scss" as *;
.NotFound {
    &_main {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        margin: 0 auto;
        max-width: 414px;
        padding: $spacer-3xl;
        text-align: center;
    }

    &_title {
        margin-bottom: $spacer-s;
        margin-top: $spacer-xxl;
    }

    &_description {
        font-size: 16px;
    }
}
